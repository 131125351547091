import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import {
    hitWarning,
    inputWarning,
    networkError,
    changeError,
} from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { recordsRepository } from "../../../repository/action"
import LinearProgress from "../../atoms/linearProgress"
import DatePicker from "../floatingButton/datePicker"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    button: {
        fontWeight: "bold",
        marginBottom: "2px",
    },
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 120,
        fontFamily: Theme.font.fontFamily,
    },
    buttonStyle: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
    },
    title: {
        display: "inline-block",
        bottom: "8px",
        position: "relative",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
    subtitle: {
        color: "grey",
        fontSize: "12px",
        fontFamily: Theme.font.fontFamily,
    },
    checkColor: {
        color: "red",
    },
    margin: {
        margin: "5px",
    },
    dialogTitle: {
        display: "inline-block",
        padding: "16px 24px 0px",
    },
}))

export default function RecordCorrectButton(props) {
    const {
        loginUser,
        distinction,
        rangeDate,
        setMonthAllRecords,
        record,
        handleClose,
        bool,
        setBool,
    } = props
    const classes = useStyles()
    const [correctbool, setCorrectBool] = React.useState([true, false])
    const [open, setOpen] = React.useState(false)
    const [hitArrow, setHitArrow] = React.useState()
    const [allArrow, setAllArrow] = React.useState()
    const [linearAnimation, setLinearAnimation] = React.useState(false)

    const list = [...Array(301).keys()]

    const handleHitChange = (event) => {
        setHitArrow(Number(event.target.value))
        if (Number(event.target.value) >= 0) {
            let disabled = [...correctbool]
            disabled[0] = false
            setCorrectBool([...disabled])
        } else {
            let disabled = [...correctbool]
            disabled[0] = true
            setCorrectBool([...disabled])
        }
    }

    const handleAllChange = (event) => {
        setAllArrow(Number(event.target.value))
        if (Number(event.target.value) >= 0) {
            let disabled = [...correctbool]
            disabled[0] = false
            setCorrectBool([...disabled])
        } else {
            let disabled = [...correctbool]
            disabled[0] = true
            setCorrectBool([...disabled])
        }
    }

    React.useEffect(() => {
        setHitArrow(record.hit_arrow)
        setAllArrow(record.all_arrow)
        setCorrectBool([true, false])
    }, [open])

    return (
        <>
            <div className={classes.buttonStyle}>
                <Button
                    disabled={
                        bool ||
                        !(
                            rangeDate.startDate.format("YYYY") == moment().format("YYYY") &&
                            rangeDate.startDate.format("M") == moment().format("M")
                        )
                    }
                    color="primary"
                    onClick={() => {
                        setOpen(true)
                    }}
                    className={classes.button}
                    startIcon={<DoubleArrowIcon />}
                >
                    <span style={fontStyle("bold")}>訂正</span>
                </Button>
            </div>
            {record && (
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open}
                    onClose={() => {
                        setOpen(false)
                    }}
                >
                    {linearAnimation && <LinearProgress />}
                    <DialogTitle className={classes.dialogTitle}>
                        <div style={fontStyle("bold")}>記録訂正</div>
                    </DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <DatePicker
                                selectedDate={record.date}
                                loginUser={loginUser}
                                disableFlag={true}
                            />
                            <div style={{ margin: "auto" }}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="demo-dialog-native">的中数</InputLabel>
                                    <Select
                                        native
                                        value={hitArrow}
                                        onChange={handleHitChange}
                                        input={<Input id="demo-dialog-native" />}
                                    >
                                        <option value="" />
                                        {list.map((num, index) => {
                                            return (
                                                <option value={num} key={index}>
                                                    {num}
                                                </option>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="demo-dialog-native">本数</InputLabel>
                                    <Select
                                        native
                                        value={allArrow}
                                        onChange={handleAllChange}
                                        input={<Input id="demo-dialog-native" />}
                                    >
                                        {/* <option value="" /> */}
                                        {list.map((num, index) => {
                                            return (
                                                <option value={num} key={index}>
                                                    {num}
                                                </option>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpen(false)
                            }}
                            color="primary"
                        >
                            <div style={fontStyle("bold")}> 閉じる</div>
                        </Button>
                        <Button
                            disabled={correctbool.findIndex((b) => b) !== -1}
                            onClick={() => {
                                let rec_name
                                if (distinction == 0) {
                                    //records
                                    rec_name = "records"
                                } else if (distinction == 1) {
                                    //records2
                                    rec_name = "records2"
                                } else if (distinction == 2) {
                                    //records3
                                    rec_name = "records3"
                                }
                                if (hitArrow < 0) {
                                    swal(hitWarning)
                                } else if (hitArrow > allArrow) {
                                    swal(inputWarning)
                                } else {
                                    setLinearAnimation(true)
                                    setBool(true)
                                    recordsRepository
                                        .recordCorrect(
                                            loginUser,
                                            record,
                                            rec_name,
                                            hitArrow,
                                            allArrow
                                        )
                                        .then(() => {
                                            return (
                                                swal({
                                                    title: "記録を変更しました",
                                                    icon: "success",
                                                    buttons: false,
                                                    timer: 1300,
                                                }),
                                                setLinearAnimation(false),
                                                setOpen(false),
                                                handleClose(),
                                                recordsRepository
                                                    .allRecordsFetch(loginUser)
                                                    .then((data) => {
                                                        setMonthAllRecords(data)
                                                        setBool(false)
                                                    })
                                                    .catch(() => {
                                                        swalErrorAction(networkError)
                                                    })
                                            )
                                        })
                                        .catch(() => {
                                            swal(changeError).then(() => {
                                                setOpen(false)
                                                localStorage.clear()
                                                navigate("/")
                                            })
                                        })
                                }
                            }}
                            color="primary"
                        >
                            <div style={fontStyle("bold")}>変更</div>
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
