import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { willDelete, networkError, deleteError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { recordsRepository } from "../../../repository/action"
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 120,
    },
    button: {
        fontWeight: "bold",
        marginBottom: "2px",
    },
    buttonStyle: {
        display: "flex",
        flexDirection: "row",
    },
    title: {
        display: "inline-block",
        bottom: "8px",
        position: "relative",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
    subtitle: {
        color: "grey",
        fontSize: "12px",
        fontFamily: Theme.font.fontFamily,
    },
    checkColor: {
        color: "red",
    },
    margin: {
        margin: "5px",
    },
}))

export default function RecordDeleteButton(props) {
    const {
        loginUser,
        distinction,
        record,
        rangeDate,
        setMonthAllRecords,
        setLinearAnimation,
        handleClose,
        bool,
        setBool,
    } = props
    const classes = useStyles()

    return (
        <div className={classes.buttonStyle}>
            <Button
                disabled={
                    bool ||
                    !(
                        rangeDate.startDate.format("YYYY") == moment().format("YYYY") &&
                        rangeDate.startDate.format("M") == moment().format("M")
                    )
                }
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => {
                    swal(willDelete).then((val) => {
                        if (val) {
                            setBool(true)
                            setLinearAnimation(true)
                            let recName
                            if (distinction == 0) {
                                //records
                                recName = "records"
                            } else if (distinction == 1) {
                                //records2
                                recName = "records2"
                            } else if (distinction == 2) {
                                //records3
                                recName = "records3"
                            }
                            recordsRepository
                                .recordDelete(loginUser, record, recName)
                                .then(() => {
                                    return (
                                        swal({
                                            title: "記録を削除しました",
                                            icon: "success",
                                            buttons: false,
                                            timer: 1300,
                                        }),
                                        setLinearAnimation(false),
                                        handleClose(),
                                        recordsRepository
                                            .allRecordsFetch(loginUser)
                                            .then((data) => {
                                                setMonthAllRecords(data)
                                                setBool(false)
                                            })
                                            .catch(() => {
                                                swalErrorAction(networkError)
                                            })
                                    )
                                })
                                .catch(() => {
                                    return swal(deleteError).then(() => {
                                        handleClose()
                                        localStorage.clear()
                                        navigate("/")
                                    })
                                })
                        }
                    })
                }}
            >
                <span style={fontStyle("bold")}>削除</span>
            </Button>
        </div>
    )
}
