import Backdrop from "@material-ui/core/Backdrop"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Fade from "@material-ui/core/Fade"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import Popper from "@material-ui/core/Popper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"
import LinearProgress from "../../atoms/linearProgress"

import RecordCorrectButton from "./recordCorrectButton"
import RecordDeleteButton from "./recordDeleteButton"

const weekdays = ["日", "月", "火", "水", "木", "金", "土"]

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 4px 0px",
        textAlign: "end",
        fontFamily: Theme.font.fontFamily,
    },
    cardStyle: {
        margin: "-10px",
        textAlign: "end",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
}))

export default function RecordsCalendar(props) {
    const {
        records,
        memberData,
        rangeDate,
        loginUser,
        distinction,
        setRecords,
        setMonthAllRecords,
    } = props
    const begining = new Date(
        rangeDate.startDate.format("YYYY"),
        Number(rangeDate.startDate.format("M")) - 1,
        1
    )
    const calendar = []
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [hitRateRecord, setHitRateRecord] = React.useState({})
    const [placement, setPlacement] = React.useState()
    const [open, setOpen] = React.useState(false)
    const [linearAnimation, setLinearAnimation] = React.useState(false)
    const [bool, setBool] = React.useState(false)

    let sortWill = [...records]
    let sortRecords = sortWill.sort(compare)

    const compare = (a, b) => {
        const dateA = Number(a.date)
        const dateB = Number(b.date)
        //////////console.log(dateA, dateB);
        let comparison = 0
        if (dateA >= dateB) {
            comparison = 1
        } else if (dateA < dateB) {
            comparison = -1
        }
        return comparison
    }

    const handleClick = (newPlacement, index) => (event) => {
        ////////console.log(index)
        if (sortRecords.length > index) {
            let obj = {
                date: sortRecords[index].date,
                hitRate: Math.round(
                    (sortRecords[index].hit_arrow / sortRecords[index].all_arrow) * 100
                ),
                hitArrow: sortRecords[index].hit_arrow,
                allArrow: sortRecords[index].all_arrow,
                record: sortRecords[index],
            }
            setAnchorEl(event.currentTarget)
            setHitRateRecord(obj)
            setOpen((prev) => placement !== newPlacement || !prev)
            setPlacement(newPlacement)
        }
    }

    const handleClose = () => {
        setHitRateRecord({})
        setAnchorEl(null)
        setOpen(false)
    }

    //カレンダータイトル
    Array.apply(null, { length: begining.getDay() })
        .map(Number.call, Number)
        .forEach(() => {
            calendar.push(
                <GridListTile>
                    {" "}
                    <Card style={{ textAlign: "end" }} />
                </GridListTile>
            )
        })
    //カレンダーの中身
    Array.apply(null, { length: 32 })
        .map(Number.call, Number)
        .forEach((i) => {
            let index
            const day = new Date(begining.getFullYear(), begining.getMonth(), 1 + i)
            sortRecords.forEach((value, ind) => {
                // ////console.log({ value, day: day.toLocaleDateString() })
                if (value.date == day.toLocaleDateString()) {
                    index = ind
                }
            })
            //////console.log(index, day.getMonth(), begining.getMonth())
            if (day.getMonth() === begining.getMonth()) {
                calendar.push(
                    <GridListTile>
                        <Card className={classes.cardStyle}>
                            <Button style={{ paddingLeft: "0px", display: "inline-flex" }}>
                                <CardContent
                                    className={classes.root}
                                    style={{ paddingBottom: "0px" }}
                                    onClick={handleClick("bottom-start", index)}
                                >
                                    <Typography>
                                        {day.toLocaleDateString().split("/")[2]}
                                    </Typography>
                                    {index > -1 &&
                                    sortRecords.some((value) => {
                                        return value.date == day.toLocaleDateString()
                                    }) ? (
                                        <Typography
                                            style={{
                                                color: Theme.color.hitNumColor,
                                                paddingBottom: "4px",
                                            }}
                                        >
                                            {" "}
                                            {Math.round(
                                                (sortRecords[index].hit_arrow /
                                                    sortRecords[index].all_arrow) *
                                                    100
                                            ) + "%"}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            style={{ paddingBottom: "28px", paddingLeft: "28px" }}
                                        />
                                    )}
                                </CardContent>
                            </Button>
                        </Card>
                    </GridListTile>
                )
            }
        })

    //////console.log({ rangeDate, records })

    return (
        <>
            {/* <Paper> */}
            <GridList cols={7} cellHeight="auto">
                {weekdays.map((w) => {
                    const styles = {}
                    if (w == "日") {
                        styles.color = "red"
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "土") {
                        styles.color = Theme.color.hitNumColor
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "月") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "火") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "水") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "木") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "金") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    return (
                        <GridListTile key={w}>
                            <Card className={classes.cardStyle}>
                                <CardContent className={classes.root}>
                                    <Typography style={styles}>
                                        <span style={fontStyle("600")}>{w}</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </GridListTile>
                    )
                })}
                {calendar}
            </GridList>
            {/* </Paper> */}
            <Backdrop open={open} onClick={handleClose} style={{ zIndex: 8, opacity: "-1" }} />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
                style={{ zIndex: 9, width: "180px" }}
            >
                {({ TransitionProps }) => (
                    <>
                        {linearAnimation && <LinearProgress margin={{ marginBottom: "-6px" }} />}
                        <Fade {...TransitionProps} timeout={350}>
                            <Card className={classes.typography}>
                                <div style={{ fontSize: "15px" }}>
                                    <div>日付：{hitRateRecord.date}</div>
                                    <div>的中数：{hitRateRecord.hitArrow}本</div>
                                    <div>本数：{hitRateRecord.allArrow}本</div>
                                    <div>的中率：{hitRateRecord.hitRate}%</div>
                                </div>
                                {window.location.pathname.split("/")[1] == "records" && records && (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <RecordDeleteButton
                                                loginUser={loginUser}
                                                memberData={memberData}
                                                distinction={distinction}
                                                record={hitRateRecord.record}
                                                rangeDate={rangeDate}
                                                setLinearAnimation={setLinearAnimation}
                                                setMonthAllRecords={setMonthAllRecords}
                                                handleClose={handleClose}
                                                bool={bool}
                                                setBool={setBool}
                                            />
                                            <RecordCorrectButton
                                                records={records}
                                                memberData={memberData}
                                                loginUser={loginUser}
                                                distinction={distinction}
                                                setRecords={setRecords}
                                                rangeDate={rangeDate}
                                                setMonthAllRecords={setMonthAllRecords}
                                                handleClose={handleClose}
                                                record={hitRateRecord.record}
                                                bool={bool}
                                                setBool={setBool}
                                            />
                                        </div>
                                        <div style={{ fontSize: "10px", color: "grey" }}>
                                            *削除、訂正は今月のみ可能
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </Fade>
                    </>
                )}
            </Popper>
        </>
    )
}
