import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import React from "react"
import {
    CartesianGrid,
    Legend,
    Tooltip,
    Area,
    Bar,
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts"

import Theme from "../../entity/theme/theme"

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: Theme.font.fontFamily,
        width: "100%",
        height: "280px",
        textAlign: "center",
        marginBottom: theme.spacing(3),
        marginTop: "20px",
    },
    hitRate: {
        color: "#00aced",
        fontWeight: "bold",
    },
    hitArrow: {
        color: "pink",
        fontWeight: "bold",
    },
    allArrow: {
        color: "darkseagreen",
        fontWeight: "bold",
    },
}))

export default function Graph(props) {
    const classes = useStyles()
    const { records } = props

    const compare = (a, b) => {
        const dateA = moment(a.date).format("X")
        const dateB = moment(b.date).format("X")
        // ////console.log(dateA, dateB);
        let comparison = 0
        if (dateA >= dateB) {
            comparison = 1
        } else if (dateA < dateB) {
            comparison = -1
        }
        return comparison
    }

    const mostCal = (records) => {
        let data = {
            all_arrow: records[0].all_arrow,
        }
        for (let i = 1; i < records.length; i++) {
            if (data.all_arrow < records[i].all_arrow) {
                data.all_arrow = records[i].all_arrow
            }
        }

        return data
    }

    let mostData
    if (records.length > 0) {
        mostData = mostCal(records)
    }

    const dataGraph = records.map((value) => {
        if (value) {
            //let date = value.date.split('/');
            return {
                date: value.date,
                的中数: value.hit_arrow,
                的中率: (value.hit_rate * mostData.all_arrow) / 100,
                本数: value.all_arrow,
            }
        }
    }, [])

    let sortDataGraph = [...dataGraph]

    // if (pickerFlag) {//月だけのPicker時のみ
    sortDataGraph.sort(compare)
    //}

    const CustomTooltip = ({ active, payload, label }) => {
        //////console.log(label, payload[0], payload[1], payload[2], payload[3]);
        if (active && payload) {
            return (
                <div className="custom-tooltip">
                    <div className={classes.hitArrow}>{`的中数：${payload[1].value}本`}</div>
                    <div className={classes.allArrow}>{`本数：${payload[2].value}本`}</div>
                    <div className={classes.hitRate}>{`的中率：${
                        Math.round(((payload[0].value * 100) / mostData.all_arrow) * 10) / 10
                    }%`}</div>
                    <div>{label}</div>
                </div>
            )
        }

        return null
    }

    // ////console.log(records)

    return (
        <>
            {/* <Card> */}
            <div className={classes.root}>
                <ResponsiveContainer id="hitrate_graph">
                    <ComposedChart //グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
                        width={600} //グラフ全体の幅を指定
                        height={280} //グラフ全体の高さを指定
                        data={sortDataGraph} //ここにArray型のデータを指定
                        margin={{
                            top: 10,
                            right: 0,
                            bottom: 0,
                            left: -30,
                        }} //marginを指定
                    >
                        <XAxis
                            dataKey="date" //Array型のデータの、X軸に表示したい値のキーを指定
                            unit="日"
                            tickFormatter={(props) => props.split("/")[2]}
                        />
                        <YAxis
                        // unit="本" // Y軸の単位
                        />
                        <YAxis
                            orientation="right"
                            yAxisId="rightYAxis"
                            domain={["dataMin", "dataMax"]}
                            ticks={[0, 25, 50, 75, 100]}
                            unit="%"
                        />

                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <CartesianGrid //グラフのグリッドを指定
                            stroke="#f5f5f5" //グリッド線の色を指定
                        />
                        <Area //面積を表すグラフ
                            type="monotone" //グラフが曲線を描くように指定。default値は折れ線グラフ
                            dataKey="的中率" //Array型のデータの、Y軸に表示したい値のキーを指定
                            stroke="#00aced" ////グラフの線の色を指定
                            fillOpacity={1} ////グラフの中身の薄さを指定
                            fill="rgba(0, 172, 237, 0.2)" //グラフの色を指定
                        />
                        <Bar //棒グラフ
                            dataKey="的中数" //Array型のデータの、Y軸に表示したい値のキーを指定
                            barSize={3} //棒の太さを指定
                            stroke="rgba(34, 80, 162, 0.2)" ////レーダーの線の色を指定
                            fillOpacity={1} //レーダーの中身の色の薄さを指定
                            fill="pink" ////レーダーの中身の色を指定
                        />
                        <Bar //棒グラフ
                            dataKey="本数" //Array型のデータの、Y軸に表示したい値のキーを指定
                            barSize={3} //棒の太さを指定
                            stroke="rgba(34, 80, 162, 0.2)" ////レーダーの線の色を指定
                            fillOpacity={1} //レーダーの中身の色の薄さを指定
                            fill="darkseagreen" ////レーダーの中身の色を指定
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            {/* </Card> */}
        </>
    )
}
